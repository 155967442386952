/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343a50;
  --card-background-color: #4169e1;
  --countdown-background-color: #433765;
  --main-text-color: #f7f6f4;
  --title-text-color: #3cba8b;
}

body {
  /*  background-color:var(--main-background-color);*/
  background: url("img/background.png");
  background-size: cover;
  font-family: "Patrick Hand", cursive;
  font-size: 1.1em;
}

h2,
p {
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}
